<template>
  <div class="loading">
    <van-loading type="spinner" color="#13b5b1" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
}
</style>